
import { computed, defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import { useHead } from "@vueuse/head";
import { useRoute, useRouter } from "vue-router";

import CFormSelect from "@/components/form/Select.vue";
import CFormInputText from "@/components/form/InputText.vue";
import CFormInputNumber from "@/components/form/InputNumber.vue";
import PCalendar from "primevue/calendar";

import { useFilter, formatCPF, formatDateEn, removeCPFMask } from "@/libs/utils";

const VTransactionFilter = defineComponent({
  name: "VTransactionFilter",
  components: { CFormInputText, CFormSelect, CFormInputNumber, PCalendar },
  setup() {
    useHead({ title: "Filtrar transações | obmed" });

    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { hasAnyFilters } = useFilter();

    const cashiers = computed(() => store.state.cashier.all);
    const filters = reactive({
      id: route.query.id || undefined,
      cd_caixa: Number(route.query.cd_caixa) || undefined,
      nm_pagante: route.query.nm_pagante?.toString() || undefined,
      nf: route.query.nf?.toString() || undefined,
      nr_cpf: route.query.nr_cpf?.toString() || undefined,
      dt_inicio: route.query.dt_inicio ? new Date(String(route.query.dt_inicio).replaceAll("-", "/")) : undefined,
      dt_fim: route.query.dt_fim ? new Date(String(route.query.dt_fim).replaceAll("-", "/")) : undefined,
    });
    const loading = reactive({ submit: false });

    async function applyFilters() {
      const dt_inicio = filters.dt_inicio ? formatDateEn(String(filters.dt_inicio)) : undefined;
      const dt_fim = filters.dt_fim ? formatDateEn(String(filters.dt_fim)) : undefined;

      router.replace({ query: { ...route.query, ...filters, dt_inicio, dt_fim } });

      loading.submit = true;
      await store.dispatch("searchCashierTransactions", {
        search: { ...filters, dt_inicio, dt_fim },
        isPrimary: true,
      });
      loading.submit = false;
    }

    function resetFilters() {
      filters.id = undefined;
      filters.cd_caixa = undefined;
      filters.nm_pagante = undefined;
      filters.nf = undefined;
      filters.nr_cpf = undefined;
      filters.dt_inicio = undefined;
      filters.dt_fim = undefined;

      applyFilters();
    }

    function handleClose() {
      router.replace({ name: "cashier-transaction-list", query: route.query });
    }

    if (!cashiers.value.length) store.dispatch("getAllCashiers");
    if (hasAnyFilters.value) applyFilters();

    return {
      ...{ cashiers, filters, loading },
      ...{ applyFilters, resetFilters, handleClose },
      ...{ formatCPF, removeCPFMask },
    };
  },
});

export default VTransactionFilter;
